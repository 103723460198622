import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Seo from "../components/seo"
import Layout from "../components/layout"
import AboutSection from "../components/aboutsection"
import Hero from "../components/hero"
import Showcase from "../components/showcase"

const IndexPage = () => {
    const title = "Cyzer Studio LLC | Home"
    const aboutText = `
        Cyzer Studio is a one-man indie game studio based in Los Angeles, California. Currently I am building a 3D platformer game in Unity Engine.
    `
    const keywords = [
        "games", "indie",
        "indie game", "indie games", 
        "indie game development", 
        "cyzer studio", "cyzer studio llc", 
        "dev log", "development log", 
        "indie dev log", "indie development log",
        "indie steam game", "indie steam games",
        "parkour game", "indie parkour game",
        "3d platformer", "indie 3d platformer"
    ]
    
    const data = useStaticQuery(
        graphql`
          query {
            thumbnail: file(relativePath: { eq: "thumbnail.PNG" }) {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `
      )
    
  return (
    <>
        <Seo
            title={ title }
            description={ aboutText }
            keywords={ keywords }
            image={ data.thumbnail.childImageSharp.fluid }
        />
        <Layout>
            <Hero 
                header="Cyzer Studio LLC"
                leading="We are building games, applications, and more..."
            />
            <AboutSection 
                header="About Us"
                leading={ aboutText }
            />
            <Showcase
                header1="Our Projects"
                leading1="Coming Soon"
                btnTxt1="Learn More"
                header2="Our Development Log"
                leading2="Coming Soon"
                btnTxt2="Learn More"
            />
        </Layout>
    </>
  )
}

export default IndexPage
