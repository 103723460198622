import React from "react"
import Helmet from "react-helmet"

const Seo = ({ title, description, keywords, image }) => {
    return (
        <Helmet htmlAttributes={{ lang: 'en' }}>
            <title>{ title }</title>
            <meta name="description" content={ description } />
            <meta name="keywords" content={ keywords.join(", ") } />
            <meta property="og:title" content={ title } />
            <meta property="og:type" content="website" />
            <meta property="og:description" content={ description } />
            <meta property="og:image" content={ image } />
            <meta property="og:locale" content="en_US" />
            <meta property="og:url" content="https://www.cyzerstudio.com" />
            <link rel="canonical" href="https://www.cyzerstudio.com" />
        </Helmet>
    )
}

export default Seo
