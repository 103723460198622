import React from "react"
// import BackgroundImage from "gatsby-background-image"

const Showcase = ({ header1, leading1, btnTxt1, imgSrc1, header2, leading2, btnTxt2, imgSrc2 }) => {
    return (
        <section className="jumbotron showcase" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
            <div className="container-fluid p-0">
                <div className="d-md-flex showcase__flex-container">
                    <div className="showcase__item showcase__item--1 rounded shadow" id="projects">
                        <div className="showcase__overlay"></div>
                        <div className="showcase__text-container pt-5">
                            <h2 className="display-3">{ header1 }</h2>
                            <p className="lead mb-5">{ leading1 }</p>
                            <a className="btn btn-outline-light btn-lg" href="#projects">{ btnTxt1 }</a>        
                        </div>
                    </div>
                    <div className="showcase__item showcase__item--2 rounded shadow" id="developmentlog">
                        <div className="showcase__overlay"></div>
                        <div className="showcase__text-container pt-5">
                            <h2 className="display-3">{ header2 }</h2>
                            <p className="lead mb-5">{ leading2 }</p>
                            <a className="btn btn-outline-light btn-lg" href="#developmentlog">{ btnTxt2 }</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Showcase
