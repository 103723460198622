import React, { useEffect } from "react"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

const AboutSection = ({ header, leading }) => {
    const { ref, inView } = useInView({
        threshold: 0.1
    })

    const headerAnimation = useAnimation();
    const leadingAnimation = useAnimation();

    useEffect(() => {
        if (inView) {
            headerAnimation.start({
                y: 0,
                opacity: 1,
                transition: {
                    duration: 1
                }
            })

            leadingAnimation.start({
                opacity: 1,
                transition: {
                    duration: 1
                }
            })
        }

        if (!inView) {
            headerAnimation.start({
                y: -100,
                opacity: 0,
            })

            leadingAnimation.start({
                opacity: 0
            })
        }
    }, [inView, headerAnimation, leadingAnimation])

    return (
        <section className="about-section text-dark mb-5" id="about" ref={ ref }>
            <div className="container p-5">
                <div className="text-container pt-2 pb-2 about-section__text-container">
                    <div className="filler" style={{ height: '25px' }}></div>
                    <motion.h2 animate={ headerAnimation } className="display-2">
                        { header }
                    </motion.h2>
                    <motion.p animate={{ leadingAnimation }} className="lead mb-5">
                        { leading }
                    </motion.p>
                </div>
            </div>
        </section>
    )
}

export default AboutSection
