import React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"

const Hero = ({ header, leading }) => {
    return (
        <div className="jumbotron hero rounded">
            <div className="container">
                <div className="text-container pt-lg-5 hero__text-container">
                    <div className="filler" style={{ height: '25px' }}></div>
                    <div className="mobile-filler d-lg-none" style={{ height: '50px' }}></div>
                    <motion.h1 initial={{ y: -100, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1 }} className="display-2">
                        { header }
                    </motion.h1>
                    <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }} className="lead mb-5">
                        { leading }
                    </motion.p>
                </div>
                <Link className="hero__arrow-link" to="/#about">
                    <motion.div initial={{ y: 100, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1 }} className="hero__arrow-container">
                        <div className="hero__arrow">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </motion.div>
                </Link>
            </div>
        </div>
    )
}

export default Hero
